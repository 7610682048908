import {
  type RegisterOptions,
  type TranslationDictionary,
} from '@shopify/react-i18n';

import {type ExpectedLocalesType} from '~/utils/constants';

type Translations = {
  [key in Exclude<
    ExpectedLocalesType,
    'en'
  >]?: () => Promise<TranslationDictionary>;
} & {
  id: string;
  en: TranslationDictionary;
};

export function createI18nConfig(translations: Translations): RegisterOptions {
  return {
    id: translations.id,
    fallback: translations.en,
    translations: (preferredLocale: string) => {
      const translation = translations[preferredLocale as ExpectedLocalesType];
      return typeof translation === 'function' ? translation() : translation;
    },
  };
}

export const emptyI18nConfig = createI18nConfig({en: {}, id: 'empty'});
